/* eslint-disable camelcase */
// import _ from 'lodash';
import axios from 'axios';
import Router from 'next/router';
import client from 'utils/workflowClient';
import * as types from './resources.types';

const updateProgress =
  (dispatch, key, filename, fileSize, part = 0) =>
  (progressEvent) => {
    dispatch({
      type: types.UPDATE_UPLOAD_STATUS,
      payload: {
        key,
        name: filename,
        uploaded: progressEvent.loaded,
        part,
        fileSize
      }
    });
  };

const listenForNewlyUploadedResource = async (key) => {
  var resourceMetadata = {};

  while (!Object.keys(resourceMetadata).length) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const { data } = await client.get(`/resources/metadata?path=${key}`);
    resourceMetadata = data;
  }

  return resourceMetadata;
};

const uploadResourceSingleUpload = async ({ file, parent, dispatch }) => {
  const filename = parent ? `${parent}${file.name}` : file.name;
  const { data = {} } = await client.post('/resources', { filename });

  const {
    resource: { url, key, contentType }
  } = data;

  await axios.put(url, file, {
    headers: {
      'x-amz-acl': 'private',
      'Content-Type': contentType
    },
    onUploadProgress: updateProgress(dispatch, key, file.name, file.size)
  });

  return key;
};

const FILE_CHUNK_SIZE = 52428800;
const SINGLE_UPLOAD_FILE_SIZE_THRESHOLD = 104857600;

const uploadResourceMultipartUpload = async ({ file, parent, dispatch }) => {
  const filename = parent ? `${parent}${file.name}` : file.name;

  const numParts = Math.ceil(file.size / FILE_CHUNK_SIZE);

  const { data = {} } = await client.post('/resources/multipart_upload/start', {
    s3_path: filename,
    filename: file.name,
    numParts
  });

  const { uploadParts, uploadId } = data;

  const promises = [];

  for (var [index, url] of uploadParts.entries()) {
    const start = index * FILE_CHUNK_SIZE;
    const end = (index + 1) * FILE_CHUNK_SIZE;
    const blob = index < uploadParts.length ? file.slice(start, end) : file.slice(start);

    promises.push(
      axios.put(url, blob, {
        headers: {
          'Content-Type': ''
        },
        onUploadProgress: updateProgress(dispatch, filename, file.name, file.size, index, uploadParts.length)
      })
    );
  }

  const responses = await Promise.all(promises);

  const partsData = responses.map((part, index) => ({
    ETag: part.headers.etag,
    PartNumber: index + 1
  }));

  await client.post('/resources/multipart_upload/finish', {
    s3_path: filename,
    uploadId,
    parts: partsData
  });

  return filename;
};

export const uploadResource = ({ file, parent }) => {
  return async (dispatch) => {
    dispatch({
      type: types.UPLOAD_RESOURCE_REQUEST
    });

    try {
      const fileSize = file.size;

      var key;
      if (fileSize <= SINGLE_UPLOAD_FILE_SIZE_THRESHOLD) {
        key = await uploadResourceSingleUpload({ file, parent, dispatch });
      } else {
        key = await uploadResourceMultipartUpload({ file, parent, dispatch });
      }

      dispatch({
        type: types.UPLOAD_RESOURCE_SUCCESS,
        payload: {
          key,
          name: file.name,
          parent: parent || ''
        }
      });

      const newResource = await listenForNewlyUploadedResource(key);

      const currentFolder = Router.router.query.folder.replace(/\/$/, '') ?? '';
      if (currentFolder == newResource.folderPath) {
        dispatch({
          type: types.ADD_SINGLE_RESOURCE_ITEM,
          payload: {
            resource: newResource
          }
        });
      }
    } catch (err) {
      console.error(err);
      return dispatch({
        type: types.UPLOAD_RESOURCE_FAILURE,
        payload: {
          error: err.response?.data.error || err.message
        }
      });
    }
  };
};

export const getResources = ({ folder = '' } = {}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RESOURCES_REQUEST
    });
    try {
      let query = '';
      if (folder !== '') query = `?folder=${encodeURIComponent(folder)}`;
      const { data = {} } = await client.get(`/resources${query}`);
      const { resources = [] } = data;

      dispatch({
        type: types.GET_RESOURCES_SUCCESS,
        payload: {
          resources
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_RESOURCES_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const getResourceMetadata = ({ inputPath = '' } = {}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_METADATA_REQUEST
    });
    try {
      const { data = {} } = await client.get(`/resources/metadata?path=${inputPath}`);

      dispatch({
        type: types.GET_METADATA_SUCCESS,
        payload: {
          metadata: data
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_METADATA_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const createFolder = ({ name = '', parent = undefined } = {}) => {
  return async (dispatch) => {
    dispatch({
      type: types.CREATE_FOLDER_REQUEST
    });
    try {
      const filename = name.endsWith('/') ? name : `${name}/`;
      const { data = {} } = await client.post('/resources', { filename, parent });
      const { item: resource = {} } = data;

      const newResource = await listenForNewlyUploadedResource(resource.id);

      dispatch({
        type: types.CREATE_FOLDER_SUCCESS,
        payload: {
          resource: newResource
        }
      });
    } catch (err) {
      return dispatch({
        type: types.CREATE_FOLDER_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const downloadResource = ({ location, downloadCustomFilename }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DOWNLOAD_RESOURCE_REQUEST
    });
    try {
      const { data = {} } = await client.post('/resources', {
        filename: location,
        download: true,
        downloadCustomFilename
      });
      const {
        resource: { url }
      } = data;
      const { items } = getState().resources;
      const downloadItem = items.filter((item) => item.s3Path);

      window.open(url, '_blank');
      dispatch({
        type: types.DOWNLOAD_RESOURCE_SUCCESS,
        payload: {
          items: downloadItem
        }
      });
    } catch (err) {
      return dispatch({
        type: types.DOWNLOAD_RESOURCE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const deleteResource = ({ selectedFiles }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DELETE_RESOURCE_REQUEST
    });
    try {
      const { data = {} } = await client.post('/resources/delete', { keys: selectedFiles });
      const { items } = getState().resources;
      const updatedItems = items.filter((item) => !selectedFiles.includes(item.s3Path));

      dispatch({
        type: types.DELETE_RESOURCE_SUCCESS,
        payload: {
          items: updatedItems
        }
      });
    } catch (err) {
      return dispatch({
        type: types.DELETE_RESOURCE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const renameResource = ({ selectedRow, name }) => {
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_RESOURCE_REQUEST
    });
    try {
      const record = { key: selectedRow, filename: name };
      const { data = {} } = await client.post('/resources/rename', { Records: [record] });

      dispatch({
        type: types.UPDATE_RESOURCE_SUCCESS,
        payload: {
          data,
          record
        }
      });
    } catch (err) {
      return dispatch({
        type: types.UPDATE_RESOURCE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
